import { Route } from '@angular/router';
import { IdentityProviders } from 'app/common/identity-providers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { environment } from 'environments/environment';

import {
	AccountRoutes,
	Auth0Routes,
	BdcRoutes,
	CustomerMonthlyDataRoutes,
	CustomerRoutes,
	DownstreamClientIdAssociationRoutes,
	LineOfBusinessRoutes,
	MaintenanceRoutes,
	NdcReplacementMappingsRoutes,
	NotFoundRoutes,
	PayorRoutes,
	RebateAggregatorRoutes,
	RebatesLoadRoutes,
	RebatesUpdateRoutes,
	RejectsLoadRoutes,
	ReportRoutes,
	SubmissionPeriodRoutes,
	UserRoutes,
	RebateAggregatorNdcRateRoutes,
	AberrantQuantityRoutes,
	UnbreakableQuantityRoutes,
	RebateGroupRoutes,
	ExcludedPharmacyRoutes,
	FormularyNdcRoutes
} from './common/routes';
import { AnonymousGuard } from './core/guards/anonymous.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
	// Redirect empty path to '/example'
	{ path: '', pathMatch: 'full', redirectTo: CustomerMonthlyDataRoutes.Root },

	// Auth routes for guests and authenticated users
	{
		path:      '',
		component: LayoutComponent,
		data:      {
			layout: 'empty'
		},
		children: [
			{
				path:         MaintenanceRoutes.Root,
				loadChildren: () =>
					import('app/modules/auth/maintenance/maintenance.routes')
			}
		]
	},

	{
		path:        '',
		canActivate: [AuthGuard()],
		component:   LayoutComponent,
		children:    [
			// Account routes
			{
				path:         AccountRoutes.Profile,
				loadChildren: () =>
					import(
						'app/modules/auth/profile-details/profile-details.routes'
					)
			},
			{
				path:         AccountRoutes.NoPermissions,
				loadChildren: () =>
					import(
						'app/modules/auth/no-permission/no-permission.routes'
					)
			},

			// Report routes
			{
				path:         ReportRoutes.Root,
				loadChildren: () => import('app/modules/report/report.routes')
			},

			// Customer Monthly Data routes
			{
				path:         CustomerMonthlyDataRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/customer-monthly-data/customer-monthly-data.routes'
					)
			},

			//Ndc Replacement Mappings routes
			{
				path:         NdcReplacementMappingsRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/ndc-replacement-mappings/ndc-replacement-mappings.routes'
					)
			},

			// Rebate Aggregators routes
			{
				path:         RebateAggregatorRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/rebate-aggregator/rebate-aggregator.routes'
					)
			},

			// Rebate Aggregators Ndc routes
			{
				path:         RebateAggregatorNdcRateRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/rebate-aggregator-ndc-rate/rebate-aggregator-ndc-rate.routes'
					)
			},

			// Formulary Ndc routes
			{
				path:         FormularyNdcRoutes.Root,
				loadChildren: () =>
					import('app/modules/formulary-ndc/formulary-ndc.routes')
			},

			// Submission period routes
			{
				path:         SubmissionPeriodRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/submission-period/submission-period.routes'
					)
			},

			// Rebates Load routes
			{
				path:         RebatesLoadRoutes.Root,
				loadChildren: () =>
					import('app/modules/rebate-load/rebate-load.routes')
			},

			// Rebates Update routes
			{
				path:         RebatesUpdateRoutes.Root,
				loadChildren: () =>
					import('app/modules/rebates-update/rebates-update.routes')
			},

			// Payors routes
			{
				path:         PayorRoutes.Root,
				loadChildren: () => import('app/modules/payors/payors.routes')
			},

			// Rejects Load routes
			{
				path:         RejectsLoadRoutes.Root,
				loadChildren: () =>
					import('app/modules/rejects-load/rejects-load.routes')
			},

			// Aberrant Quantity routes
			{
				path:         AberrantQuantityRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/aberrant-quantity/aberrant-quantity.routes'
					)
			},

			// Line of business routes
			{
				path:         LineOfBusinessRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/line-of-business/line-of-business.routes'
					)
			},

			// Bdc routes
			{
				path:         BdcRoutes.Root,
				loadChildren: () => import('app/modules/bdc/bdc.routes')
			},

			// User routes
			{
				path:         UserRoutes.Root,
				loadChildren: () => import('app/modules/user/user.routes')
			},

			// Customer routes
			{
				path:         CustomerRoutes.Root,
				loadChildren: () =>
					import('app/modules/customer/customer.routes')
			},

			// Downstream Client Id Association routes
			{
				path:         DownstreamClientIdAssociationRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/downstream-client-id-association/downstream-client-id-association.routes'
					)
			},

			// Unbreakable Quantity routes
			{
				path:         UnbreakableQuantityRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/unbreakable-quantity/unbreakable-quantity.routes'
					)
			},

			// Rebate Group routes
			{
				path:         RebateGroupRoutes.Root,
				loadChildren: () =>
					import('app/modules/rebate-group/rebate-group.routes')
			},

			// Excluded Pharmacies routes
			{
				path:         ExcludedPharmacyRoutes.Root,
				loadChildren: () =>
					import(
						'app/modules/excluded-pharmacy/excluded-pharmacy.routes'
					)
			}
		]
	}
];

if (environment.identityProvider === IdentityProviders.Auth0) {
	appRoutes.push(
		// Auth routes for authenticated users only
		{
			path:        '',
			canActivate: [AuthGuard()],
			component:   LayoutComponent,
			data:        {
				layout: 'empty'
			},
			children: [
				{
					path:         Auth0Routes.Callback,
					loadChildren: () =>
						import('app/modules/auth/callback/callback.routes')
				}
			]
		},

		// Auth routes for guests and authenticated users
		{
			path:      '',
			component: LayoutComponent,
			data:      {
				layout: 'empty'
			},
			children: [
				{
					path:         Auth0Routes.AuthenticateError,
					loadChildren: () =>
						import(
							'app/modules/auth/auth0-authenticate-error/auth0-authenticate-error.routes'
						)
				}
			]
		}
	);
} else {
	appRoutes.push(
		// Auth routes for guests only
		{
			path:        '',
			canActivate: [AnonymousGuard],
			component:   LayoutComponent,
			data:        {
				layout: 'empty'
			},
			children: [
				{
					path:         'confirmation-required',
					loadChildren: () =>
						import(
							'app/modules/auth/confirmation-required/confirmation-required.routes'
						)
				},
				{
					path:         AccountRoutes.ForgotPassword,
					loadChildren: () =>
						import(
							'app/modules/auth/forgot-password/forgot-password.routes'
						)
				},
				{
					path:         AccountRoutes.SignIn,
					loadChildren: () =>
						import('app/modules/auth/sign-in/sign-in.routes')
				},
				{
					path:         AccountRoutes.ConfirmPasswordReset,
					loadChildren: () =>
						import(
							'app/modules/auth/password-reset-verify-code/password-reset-verify-code.routes'
						)
				},
				{
					path:         AccountRoutes.ConfirmAccount,
					loadChildren: () =>
						import(
							'app/modules/auth/password-reset-verify-code/password-reset-verify-code.routes'
						)
				}
			]
		},

		// Auth routes for authenticated users only
		{
			path:        '',
			canActivate: [AuthGuard()],
			component:   LayoutComponent,
			data:        {
				layout: 'empty'
			},
			children: [
				{
					path:         AccountRoutes.SignOut,
					loadChildren: () =>
						import('app/modules/auth/sign-out/sign-out.routes')
				},
				{
					path:         AccountRoutes.ConfirmPasswordChange,
					loadChildren: () =>
						import(
							'app/modules/auth/password-reset-verify-code/password-reset-verify-code.routes'
						)
				}
			]
		}
	);
}

// Not Found Page
appRoutes.push(
	{
		path:       '**',
		redirectTo: NotFoundRoutes.Root,
		pathMatch:  'full'
	},
	{
		path:      '',
		component: LayoutComponent,
		data:      {
			layout: 'empty'
		},
		children: [
			{
				path:         NotFoundRoutes.Root,
				loadChildren: () =>
					import('app/modules/auth/not-found/not-found.routes')
			}
		]
	}
);
