import { createAction, props } from '@ngrx/store';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { SearchResponse } from 'app/models/api/search-response';
import { FacilityModel } from 'app/models/api/facility-model';
import { FacilityTableRecordModel } from 'app/models/dto/facility-table-record-model';
import { FacilitySearchFilterModel } from 'app/models/api/facility-search-filter-model';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { NcpdpDetailsModel } from 'app/models/api/ncpdp-details-model';
import { FetchCustomerFacilityRequest } from 'app/models/dto/fetch-customer-facility-request';
import { CustomerDetailsModel } from 'app/models/api/customer-details-model';
import { LocationNcpdpSearchFilterModel } from 'app/models/api/location-ncpdp-search-filter-model';
import { NcpdpHistoryTransactionModel } from 'app/models/api/ncpdp-history-transaction-model';
import { MapFacilityModel } from 'app/models/api/map-facility-model';

export enum FacilityActions {
	DeactivateFacility = '[Facility] DeactivateFacility',
	FacilityDeactivated = '[Facility] FacilityDeactivated',

	LoadCustomerFacility = '[Facility] LoadCustomerFacility',
	CustomerFacilityNotFound = '[Facility] CustomerFacilityNotFound',
	CustomerFacilityLoaded = '[Facility] CustomerFacilityLoaded',

	PreloadCustomerFacility = '[Facility] PreloadCustomerFacility',
	PreloadedCustomerFacilityNotFound = '[Facility] PreloadedCustomerFacilityNotFound',
	CustomerFacilityPreloaded = '[Facility] CustomerFacilityPreloaded',

	LoadCustomerFacilities = '[Facility] LoadCustomerFacilities',
	CustomerFacilitiesLoaded = '[Facility] CustomerFacilitiesLoaded',

	CreateCustomerFacility = '[Facility] CreateCustomerFacility',
	CustomerFacilityCreated = '[Facility] CustomerFacilityCreated',

	UpdateCustomerFacility = '[Facility] UpdateCustomerFacility',
	CustomerFacilityUpdated = '[Facility] CustomerFacilityUpdated',

	LoadFacilityOptions = '[Facility] LoadFacilityOptions',
	FacilityOptionsLoaded = '[Facility] FacilityOptionsLoaded',

	SetCurrentCustomer = '[Facility] SetCurrentCustomer',
	CurrentCustomerSet = '[Facility] CurrentCustomerSet',

	SetMappingId = '[Facility] SetMappingId',

	MapFacilityMapping = '[Facility] MapFacilityMapping',
	FacilityMappingMapped = '[Facility] FacilityMappingMapped',

	LoadNcpdpFacility = '[Facility] LoadNcpdpFacility',
	NcpdpFacilityLoaded = '[Facility] NcpdpFacilityLoaded',

	LoadNcpdpLocationHistory = '[Facility] LoadNcpdpLocationHistory',
	NcpdpLocationHistoryLoaded = '[Facility] NcpdpLocationHistoryLoaded',

	CheckNcpdpExcluded = '[Customer] CheckNcpdpExcluded',
	NcpdpExcludedChecked = '[Customer] NcpdpExcludedChecked',

	ErrorOccurred = '[Facility] ErrorOccurred'
}

export const loadFacilityOptions = createAction(
	FacilityActions.LoadFacilityOptions,
	props<ActionRequestPayload<number>>()
);

export const facilityOptionsLoaded = createAction(
	FacilityActions.FacilityOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadMappingsOptions = createAction(
	FacilityActions.LoadFacilityOptions,
	props<ActionRequestPayload<number>>()
);

export const mappingsOptionsLoaded = createAction(
	FacilityActions.FacilityOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadProceededMappings = createAction(
	FacilityActions.LoadFacilityOptions,
	props<ActionRequestPayload<number>>()
);

export const proceededMappingsLoaded = createAction(
	FacilityActions.FacilityOptionsLoaded,
	props<ActionResponsePayload<MapFacilityModel[]>>()
);

export const preloadCustomerFacility = createAction(
	FacilityActions.PreloadCustomerFacility,
	props<ActionRequestPayload<FetchCustomerFacilityRequest>>()
);

export const preloadedCustomerFacilityNotFound = createAction(
	FacilityActions.PreloadedCustomerFacilityNotFound
);

export const customerFacilityPreloaded = createAction(
	FacilityActions.CustomerFacilityPreloaded,
	props<ActionResponsePayload<FacilityModel>>()
);

export const loadCustomerFacilities = createAction(
	FacilityActions.LoadCustomerFacilities,
	props<
		ActionResponsePayload<FilterSearchRequest<FacilitySearchFilterModel>>
	>()
);

export const customerFacilityNotFound = createAction(
	FacilityActions.CustomerFacilityNotFound
);

export const customerFacilitiesLoaded = createAction(
	FacilityActions.CustomerFacilitiesLoaded,
	props<ActionResponsePayload<SearchResponse<FacilityTableRecordModel>>>()
);

export const updateCustomerFacility = createAction(
	FacilityActions.UpdateCustomerFacility,
	props<ActionRequestPayload<FacilityModel>>()
);

export const customerFacilityUpdated = createAction(
	FacilityActions.CustomerFacilityUpdated
);

export const createCustomerFacility = createAction(
	FacilityActions.CreateCustomerFacility,
	props<ActionRequestPayload<FacilityModel>>()
);

export const customerFacilityCreated = createAction(
	FacilityActions.CustomerFacilityCreated
);

export const loadCustomerFacility = createAction(
	FacilityActions.LoadCustomerFacility,
	props<ActionRequestPayload<FetchCustomerFacilityRequest>>()
);

export const customerFacilityLoaded = createAction(
	FacilityActions.CustomerFacilityLoaded,
	props<ActionRequestPayload<FacilityModel>>()
);

export const deactivateFacility = createAction(
	FacilityActions.DeactivateFacility,
	props<ActionRequestPayload<number>>()
);

export const facilityDeactivated = createAction(
	FacilityActions.FacilityDeactivated
);

export const setCurrentCustomer = createAction(
	FacilityActions.SetCurrentCustomer,
	props<ActionRequestPayload<CustomerDetailsModel>>()
);

export const currentCustomerSet = createAction(
	FacilityActions.CurrentCustomerSet,
	props<ActionResponsePayload<CustomerDetailsModel>>()
);

export const setMappingId = createAction(
	FacilityActions.SetMappingId,
	props<ActionRequestPayload<number>>()
);

export const mapFacilityMapping = createAction(
	FacilityActions.MapFacilityMapping,
	props<ActionRequestPayload<MapFacilityModel>>()
);

export const facilityMappingMapped = createAction(
	FacilityActions.FacilityMappingMapped
);

export const loadNcpdpFacility = createAction(
	FacilityActions.LoadNcpdpFacility,
	props<ActionRequestPayload<string>>()
);

export const ncpdpFacilityLoaded = createAction(
	FacilityActions.NcpdpFacilityLoaded,
	props<ActionResponsePayload<NcpdpDetailsModel>>()
);

export const loadNcpdpLocationHistory = createAction(
	FacilityActions.LoadNcpdpLocationHistory,
	props<
		ActionRequestPayload<
			FilterSearchRequest<LocationNcpdpSearchFilterModel>
		>
	>()
);

export const ncpdpLocationHistoryLoaded = createAction(
	FacilityActions.NcpdpLocationHistoryLoaded,
	props<ActionResponsePayload<SearchResponse<NcpdpHistoryTransactionModel>>>()
);

export const checkNcpdpExcluded = createAction(
	FacilityActions.CheckNcpdpExcluded,
	props<ActionRequestPayload<string>>()
);

export const ncpdpExcludedChecked = createAction(
	FacilityActions.NcpdpExcludedChecked,
	props<ActionResponsePayload<boolean>>()
);

export const errorOccurred = createAction(FacilityActions.ErrorOccurred);
