import { FuseNavigationItem } from '@fuse/components/navigation';
import { PermissionScopes } from 'app/common/permission-scopes';
import {
	CustomerRoutes,
	LineOfBusinessRoutes,
	PayorRoutes,
	RebateAggregatorRoutes,
	RebatesLoadRoutes,
	RebatesUpdateRoutes,
	RejectsLoadRoutes,
	ReportRoutes,
	UserRoutes,
	BdcRoutes,
	CustomerMonthlyDataRoutes,
	NdcReplacementMappingsRoutes,
	DownstreamClientIdAssociationRoutes,
	SubmissionPeriodRoutes,
	RebateAggregatorNdcRateRoutes,
	AberrantQuantityRoutes,
	UnbreakableQuantityRoutes,
	RebateGroupRoutes,
	ExcludedPharmacyRoutes,
	FormularyNdcRoutes
} from 'app/common/routes';
import { PermissionModelTypes } from 'app/models/api/permission-model-types';

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id:    'CustomerMonthlyData',
		title: 'Rebate Planning Page',
		type:  'basic',
		icon:  'fa-chart-pie',
		link:  `${CustomerMonthlyDataRoutes.Root}`,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.CustomerMonthlyData,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'reports',
		title: 'Reports',
		type:  'basic',
		icon:  'fa-file-lines',
		link:  `${ReportRoutes.Root}`,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.Report,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'RebatesLoad',
		title: 'Rebates Load',
		type:  'basic',
		icon:  'fa-arrow-up-from-bracket',
		link:  RebatesLoadRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.RebatesLoad,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'RebatesUpdate',
		title: 'Rebates Update',
		type:  'basic',
		icon:  'fa-pen-to-square',
		link:  RebatesUpdateRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.RebatesUpdate,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'Customers',
		title: 'Customers',
		type:  'basic',
		icon:  'fa-id-card',
		link:  CustomerRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.Customers,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'Payor',
		title: 'Payors',
		type:  'basic',
		icon:  'fa-credit-card',
		link:  PayorRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.Payor,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:    'RejectsLoad',
		title: 'Rejects Load',
		type:  'basic',
		icon:  'fa-arrow-up-from-bracket',
		link:  RejectsLoadRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.RejectsLoad,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:       'RebateEligible',
		title:    'Rebate Eligible',
		type:     'collapsable',
		icon:     'fa-arrow-up-from-bracket',
		children: [
			{
				id:    'RebateAggregatorNdcRates',
				title: 'NDC Rates',
				type:  'basic',
				link:  RebateAggregatorNdcRateRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.RebateAggregatorNdcRates,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'FormularyNdc',
				title: 'Formulary NDC',
				type:  'basic',
				link:  FormularyNdcRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.FormularyNdc,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'DownstreamClientId',
				title: 'Group ID',
				type:  'basic',
				link:  DownstreamClientIdAssociationRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.DownstreamClientIdAssociation,
							permission: PermissionModelTypes.Read
						}
					]
				}
			}
		]
	},
	{
		id:    'AberrantQuantities',
		title: 'Aberrant Quantities',
		type:  'basic',
		icon:  'fa-list-check',
		link:  AberrantQuantityRoutes.Root,
		meta:  {
			allowedPermissions: [
				{
					scope:      PermissionScopes.AberrantQuantity,
					permission: PermissionModelTypes.Read
				}
			]
		}
	},
	{
		id:       'AdminSettings',
		title:    'Admin Settings',
		type:     'collapsable',
		icon:     'fa-gear',
		children: [
			{
				id:    'LineOfBusiness',
				title: 'Line of Business',
				type:  'basic',
				link:  LineOfBusinessRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.LineOfBusiness,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'Bdc',
				title: 'Third Party Setup',
				type:  'basic',
				link:  BdcRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.ThirdParty,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'NdcReplacementMappings',
				title: 'NDC Replacement Mappings',
				type:  'basic',
				link:  NdcReplacementMappingsRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.NdcReplacementMappings,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'Users',
				title: 'Users',
				type:  'basic',
				link:  `${UserRoutes.Root}/${UserRoutes.Users}`,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.Users,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'Roles',
				title: 'Roles',
				type:  'basic',
				link:  `${UserRoutes.Root}/${UserRoutes.Roles}`,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.Roles,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'SubmissionPeriod',
				title: 'Submission Period',
				type:  'basic',
				link:  SubmissionPeriodRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.SubmissionPeriod,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'UnbreakableQuantities',
				title: 'Manage Unbreakable Quantities',
				type:  'basic',
				link:  UnbreakableQuantityRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.UnbreakableQuantity,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'RebateGroups',
				title: 'Rebate Groups',
				type:  'basic',
				link:  RebateGroupRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.RebateGroup,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'ExcludedPharmacy',
				title: 'Excluded Pharmacies',
				type:  'basic',
				link:  ExcludedPharmacyRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.ExcludedPharmacy,
							permission: PermissionModelTypes.Read
						}
					]
				}
			},
			{
				id:    'RebateAggregators',
				title: 'Rebate Aggregators',
				type:  'basic',
				link:  RebateAggregatorRoutes.Root,
				meta:  {
					allowedPermissions: [
						{
							scope:      PermissionScopes.RebateAggregators,
							permission: PermissionModelTypes.Read
						}
					]
				}
			}
		]
	}
];
