export enum ActionRoutes {
	New = 'new',
	Update = 'update',
	Create = 'create',
	Add = 'add'
}

export enum RouteParams {
	Id = 'id',
	ChildId = 'childId',
	ReportTypeParam = 'reportType',
	RebateLoadFileId = 'rebateLoadFileId',
	CustomerIdParam = 'customerId',
	LobIds = 'lobIds',
	SubmissionPeriodIdParam = 'submissionPeriodId',
	RecentSubmissionsParam = 'recentSubmissions',
	StatisticsLevel = 'statisticsLevel',
	LocationIdParam = 'locationId',
	IsUserCustomers = 'isUserCustomers'
}

export enum AccountRoutes {
	SignIn = 'sign-in',
	SignOut = 'sign-out',
	VerifyCode = 'verify-code',
	Profile = 'profile',
	NoPermissions = 'no-permissions',
	ConfirmAccount = 'confirm-account',
	ForgotPassword = 'forgot-password',
	ConfirmPasswordReset = 'confirm-password-reset',
	ConfirmPasswordChange = 'confirm-password-change',
	PasswordResetRequired = 'confirm-password-required'
}

export enum Auth0Routes {
	Callback = 'callback',
	AuthenticateError = 'authenticate-error'
}

export enum UserRoutes {
	Root = 'user-management',
	Users = 'users',
	Roles = 'roles'
}

export enum ReportRoutes {
	Root = 'reports'
}

export enum CustomerMonthlyDataRoutes {
	Root = 'customer-monthly-data',
	CustomerNotes = 'customer-notes',
	MonthlyScriptCount = 'monthly-script-count',
	ScriptCount = 'script-count',
	TotalWac = 'total-wac',
	FillDates = 'fill-dates'
}

export enum CustomerRoutes {
	Root = 'customers',
	CreateFacility = 'create-facility',
	UpdateFacility = 'update-facility',
	RetentionRates = 'retention-rates',
	CreateCustomerBdc = 'create-customer-bdc',
	UpdateCustomerBdc = 'update-customer-bdc',
	CreateCustomDateOfService = 'create-custom-dos',
	UpdateCustomDateOfService = 'update-custom-dos',
	Notes = 'notes'
}

export enum AuditRoutes {
	Root = 'audit-log'
}

export enum FormularyNdcRoutes {
	Root = 'formulary-ndc'
}

export enum RebateAggregatorRoutes {
	Root = 'rebate-aggregators',
	Formulary = 'formulary'
}

export enum MaintenanceRoutes {
	Root = 'maintenance'
}

export enum NotFoundRoutes {
	Root = 'not-found'
}

export enum SubmissionPeriodRoutes {
	Root = 'submission-period'
}

export enum RebateAggregatorNdcRateRoutes {
	Root = 'rebate-aggregator-ndc-rate',
	Create = 'create',
	Add = 'add',
	Update = 'update'
}

export enum RebatesLoadRoutes {
	Root = 'rebates-load',
	New = 'new',
	Mapping = 'mapping',
	InvalidRecords = 'invalid-records',
	WarningRecords = 'warning-records',
	Locations = 'locations',
	FileDuplicates = 'file-duplicate',
	CrossFileDuplicates = 'cross-file-duplicate',
	Aggregations = 'aggregations',
	Conversions = 'conversions',
	Converted = 'converted',
	NotConverted = 'not-converted',
	CustomerNotes = 'customer-notes',
	Indicator = 'indicator',
	PharmacyIdReview = 'pharmacy-id-review'
}

export enum RejectsLoadRoutes {
	Root = 'rejects-load',
	New = 'new',
	Mapping = 'mapping'
}

export enum RebatesUpdateRoutes {
	Root = 'rebates-update',
	History = 'history'
}

export enum PayorRoutes {
	Root = 'payors',
	Reviewed = 'reviewed',
	NotReviewed = 'not-reviewed'
}

export enum LineOfBusinessRoutes {
	Root = 'line-of-business',
	Create = 'create',
	Edit = 'edit',
	Subtype = 'subtype'
}

export enum BdcRoutes {
	Root = 'bdc',
	Create = 'create'
}

export enum NdcReplacementMappingsRoutes {
	Root = 'ndc-replacement',
	Create = 'create'
}

export enum DownstreamClientIdAssociationRoutes {
	Root = 'downstream-client-id-association',
	Create = 'create'
}

export enum AberrantQuantityRoutes {
	Root = 'aberrant-quantities'
}

export enum UnbreakableQuantityRoutes {
	Root = 'unbreakable-quantities'
}

export enum RebateGroupRoutes {
	Root = 'rebate-groups',
	Formularies = 'formularies',
	Rules = 'rules'
}

export enum ExcludedPharmacyRoutes {
	Root = 'excluded-pharmacy'
}
