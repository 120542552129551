import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import { longMessageNotificationDuration } from 'app/common/app-constants';
import { NotificationModel } from 'app/models/dto/notification-model';
import { AberrantQuantityActions } from 'app/store/aberrant-quantity/aberrant-quantity.actions';
import { AccountActions } from 'app/store/account/account.actions';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { BdcActions } from 'app/store/bdc/bdc.actions';
import { CustomerBdcActions } from 'app/store/customer-bdc/customer-bdc.actions';
import { CustomerCustomDateOfServiceActions } from 'app/store/customer-custom-date-of-service/customer-custom-date-of-service.actions';
import { CustomerActions } from 'app/store/customer/customer.actions';
import { ExcludedPharmacyActions } from 'app/store/excluded-pharmacy/excluded-pharmacy.actions';
import { FacilityActions } from 'app/store/facility/facility.actions';
import { LineOfBusinessSubtypeActions } from 'app/store/line-of-business-subtype/line-of-business-subtype.actions';
import { LineOfBusinessActions } from 'app/store/line-of-business/line-of-business.actions';
import { NdcReplacementMappingsActions } from 'app/store/ndc-replacement-mappings/ndc-replacement-mappings.actions';
import { PayorsActions } from 'app/store/payors/payors.actions';
import { RebateAggregatorNdcRatesActions } from 'app/store/rebate-aggregator-ndc-rates/rebate-aggregator-ndc-rates.actions';
import { RebateAggregatorFormularyActions } from 'app/store/rebate-aggregator-formulary/rebate-aggregator-formulary.actions';
import { FormularyNdcActions } from 'app/store/formulary-ndc/formulary-ndc.actions';
import { RebateGroupActions } from 'app/store/rebate-group/rebate-group.actions';
import { RebatesLoadActions } from 'app/store/rebates-load/rebates-load.actions';
import { RebateAggregatorActions } from 'app/store/rebate-aggregator/rebate-aggregator.actions';
import { RebatesUpdateActions } from 'app/store/rebates-update/rebates-update.actions';
import { RejectsLoadActions } from 'app/store/rejects-load/rejects-load.actions';
import { ReportActions } from 'app/store/report/report.actions';
import { UnbreakableQuantityActions } from 'app/store/unbreakable-quantity/unbreakable-quantity.actions';
import { UserActions } from 'app/store/user/user.actions';
import { skip } from 'rxjs';
import { match } from 'ts-pattern';

import { ToastService } from './toast.service';

type ActionPayload = Action &
	(ActionRequestPayload<unknown> | ActionResponsePayload<unknown>);

@Injectable({ providedIn: 'root' })
export class EventNotifierService {
	constructor(
		private readonly actionSubject: ActionsSubject,
		private readonly toastService: ToastService
	) {}

	public startListen(): void {
		this.actionSubject
			.pipe(skip(1))
			.subscribe((actionPayload: ActionPayload) => {
				const notificationData =
					this.getNotificationData(actionPayload);

				if (notificationData) {
					this.toastService.show(notificationData);
				}
			});
	}

	private getNotificationData({
		type,
		data
	}: ActionPayload): NotificationModel {
		return match<string, NotificationModel>(type)
			.with(AccountActions.CurrentUserInfoSaved, () => ({
				translationPath: 'account.success.currentUserInfoSaved'
			}))
			.with(AccountActions.PasswordResetConfirmed, () => ({
				translationPath: 'account.success.passwordResetConfirmed'
			}))
			.with(
				AccountActions.MfaVerificationStarted,
				AccountActions.PasswordResetVerifyCodeSent,
				() => ({
					translationPath: 'account.success.codeSent'
				})
			)
			.with(AccountActions.ForgotPasswordRequestSent, () => ({
				translationPath: 'account.success.forgotPasswordEmailWasSend',
				duration:        longMessageNotificationDuration
			}))
			.with(AccountActions.CurrentUserPasswordReseted, () => ({
				translationPath:
					'account.success.resetCurrentUserPasswordEmailSent',
				duration: longMessageNotificationDuration
			}))
			.with(UserActions.UserDeleted, () => ({
				translationPath: 'user.success.userDeleted'
			}))
			.with(UserActions.EditableUserSaved, () => ({
				translationPath: 'user.success.userSaved'
			}))
			.with(UserActions.RoleSaved, () => ({
				translationPath: 'user.success.roleSaved'
			}))
			.with(CustomerActions.CustomerCreated, () => ({
				translationPath: 'customer.success.customerSaved'
			}))
			.with(CustomerActions.CustomerUpdated, () => ({
				translationPath: 'customer.success.customerSaved'
			}))
			.with(FacilityActions.CustomerFacilityCreated, () => ({
				translationPath: 'facility.success.facilitySaved'
			}))
			.with(FacilityActions.CustomerFacilityUpdated, () => ({
				translationPath: 'facility.success.facilitySaved'
			}))
			.with(FacilityActions.FacilityMappingMapped, () => ({
				translationPath: 'rebatesLoad.success.facilityMapped'
			}))
			.with(CustomerActions.RetentionRateCreated, () => ({
				translationPath: 'customer.success.retentionRateCreated'
			}))
			.with(CustomerActions.RetentionRateUpdated, () => ({
				translationPath: 'customer.success.retentionRateUpdated'
			}))
			.with(CustomerActions.RetentionRateDeleted, () => ({
				translationPath: 'customer.success.retentionRateDeleted'
			}))
			.with(CustomerActions.NoteCreated, () => ({
				translationPath: 'customer.success.noteCreated'
			}))
			.with(CustomerActions.NoteUpdated, () => ({
				translationPath: 'customer.success.noteUpdated'
			}))
			.with(CustomerActions.NoteDeleted, () => ({
				translationPath: 'customer.success.noteDeleted'
			}))
			.with(CustomerActions.NoteRestored, () => ({
				translationPath: 'customer.success.noteRestored'
			}))
			.with(CustomerBdcActions.CustomerBdcCreated, () => ({
				translationPath: 'customer.success.thirdPartyAssociationCreated'
			}))
			.with(CustomerBdcActions.CustomerBdcUpdated, () => ({
				translationPath: 'customer.success.thirdPartyAssociationUpdated'
			}))
			.with(CustomerBdcActions.CustomerBdcDeleted, () => ({
				translationPath: 'customer.success.thirdPartyAssociationDeleted'
			}))
			.with(
				CustomerCustomDateOfServiceActions.CustomDateOfServiceCreated,
				() => ({
					translationPath:
						'customer.success.customDateOfServiceCreated'
				})
			)
			.with(
				CustomerCustomDateOfServiceActions.CustomDateOfServiceUpdated,
				() => ({
					translationPath:
						'customer.success.customDateOfServiceUpdated'
				})
			)
			.with(
				CustomerCustomDateOfServiceActions.CustomDateOfServiceDeleted,
				() => ({
					translationPath:
						'customer.success.customDateOfServiceDeleted'
				})
			)
			.with(AberrantQuantityActions.CreateAberrantQuantity, () => ({
				translationPath:
					'aberrantQuantity.success.aberrantQuantityCreated'
			}))
			.with(AberrantQuantityActions.AberrantQuantityUpdated, () => ({
				translationPath:
					'aberrantQuantity.success.aberrantQuantityUpdated'
			}))
			.with(AberrantQuantityActions.AberrantQuantityDeleted, () => ({
				translationPath:
					'aberrantQuantity.success.aberrantQuantityDeleted'
			}))
			.with(BdcActions.BdcCreated, () => ({
				translationPath: 'bdc.success.thirdPartyCreated'
			}))
			.with(BdcActions.BdcUpdated, () => ({
				translationPath: 'bdc.success.thirdPartyUpdated'
			}))
			.with(BdcActions.DeleteBdc, () => ({
				translationPath: 'bdc.success.thirdPartyDeleted'
			}))
			.with(RebateAggregatorActions.RebateAggregatorCreated, () => ({
				translationPath:
					'rebateAggregator.success.rebateAggregatorCreated'
			}))
			.with(RebateAggregatorActions.RebateAggregatorUpdated, () => ({
				translationPath:
					'rebateAggregator.success.rebateAggregatorUpdated'
			}))
			.with(
				RebateAggregatorFormularyActions.RebateAggregatorFormularyCreated,
				() => ({
					translationPath:
						'rebateAggregator.success.rebateAggregatorFormularyCreated'
				})
			)
			.with(
				RebateAggregatorFormularyActions.RebateAggregatorFormularyUpdated,
				() => ({
					translationPath:
						'rebateAggregator.success.rebateAggregatorFormularyUpdated'
				})
			)
			.with(
				RebateAggregatorFormularyActions.RebateAggregatorFormularyDeleted,
				() => ({
					translationPath:
						'rebateAggregator.success.rebateAggregatorFormularyDeleted'
				})
			)
			.with(
				RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateCreated,
				() => ({
					translationPath:
						'rebateAggregatorNdcRate.success.rebateAggregatorsNdcRateCreated'
				})
			)
			.with(
				RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateUpdated,
				() => ({
					translationPath:
						'rebateAggregatorNdcRate.success.rebateAggregatorsNdcRateUpdated'
				})
			)
			.with(
				RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateAdded,
				() => ({
					translationPath:
						'rebateAggregatorNdcRate.success.rebateAggregatorsNdcRateAdded'
				})
			)
			.with(
				RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatesDownloaded,
				() => ({
					translationPath:
						'rebateAggregatorNdcRate.success.rebateAggregatorsNdcRateDownloaded'
				})
			)
			.with(
				RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatesFileImported,
				() => ({
					translationPath:
						'rebateAggregatorNdcRate.success.rebateAggregatorsNdcRateFileImported'
				})
			)
			.with(FormularyNdcActions.FormularyNdcCreated, () => ({
				translationPath: 'formularyNdc.success.formularyNdcCreated'
			}))
			.with(FormularyNdcActions.FormularyNdcUpdated, () => ({
				translationPath: 'formularyNdc.success.formularyNdcUpdated'
			}))
			.with(FormularyNdcActions.FormularyNdcListDownloaded, () => ({
				translationPath:
					'formularyNdc.success.formularyNdcListDownloaded'
			}))
			.with(FormularyNdcActions.FormularyNdcListImported, () => ({
				translationPath: 'formularyNdc.success.formularyNdcListImported'
			}))
			.with(RebatesLoadActions.RebatesLoadFileAdded, () => ({
				translationPath:
					'rebatesLoad.success.loadRebatesLoadFileDetails'
			}))
			.with(
				RebatesLoadActions.RebatesLoadFileReadingStarted,
				RebatesLoadActions.ValidateRebateLoadFile,
				RebatesLoadActions.UpdatedRebatesRecordsValidationStarted,
				RebatesLoadActions.ExecuteRebateLoadConversion,
				RebatesLoadActions.ExecuteProcessRebateLoadFile,
				() => ({
					translationPath:
						'rebatesLoad.success.rebatesLoadFileValidationStarted'
				})
			)
			.with(RebatesLoadActions.AberrantQuantityRecordsResolved, () => ({
				translationPath: 'rebatesLoad.success.aberrantRecordsResolved'
			}))
			.with(
				RebatesLoadActions.FilteredAberrantQuantityRecordsResolved,
				() => ({
					translationPath:
						'rebatesLoad.success.aberrantRecordsResolved'
				})
			)
			.with(RebatesLoadActions.RebatesLoadTemplateSaved, () => ({
				translationPath: 'rebatesLoad.success.rebatesLoadTemplateSaved'
			}))
			.with(RebatesLoadActions.RebatesLoadFileDeleted, () => ({
				translationPath: 'rebatesLoad.success.fileDeleted'
			}))
			.with(
				RebatesLoadActions.RebatesLoadFileInvalidRecordsDeleted,
				() => ({
					translationPath: 'rebatesLoad.success.recordsDeleted'
				})
			)
			.with(RebatesLoadActions.InvalidRecordsBulkUpdateStarted, () => ({
				translationPath:
					'rebatesLoad.success.invalidRecordsBulkUpdateStarted'
			}))
			.with(
				RebatesLoadActions.AllRebatesLoadFileInvalidRecordsDeleted,
				() => ({
					translationPath: 'rebatesLoad.success.allRecordsDeleted'
				})
			)
			.with(
				RebatesLoadActions.FileDuplicatesResolved,
				RebatesLoadActions.DuplicatesResolved,
				() => ({
					translationPath: 'rebatesLoad.success.duplicateResolved'
				})
			)
			.with(
				RebatesLoadActions.AllFileDuplicatesResolved,
				RebatesLoadActions.AllDuplicatesResolved,
				() => ({
					translationPath: 'rebatesLoad.success.allDuplicatesResolved'
				})
			)
			.with(RebatesLoadActions.RebateLoadQuantitiesSetted, () => ({
				translationPath: 'rebatesLoad.success.quantitiesUpdated'
			}))
			.with(RebatesLoadActions.FilteredRecordsMarkedAsDeleted, () => ({
				translationPath: 'rebatesLoad.success.rowsMarkedAsDeleted',
				params:          {
					rowsCount: data['count']
				}
			}))
			.with(RebatesLoadActions.SubmissionPeriodChanged, () => ({
				translationPath: 'rebatesLoad.success.submissionPeriodChanged'
			}))
			.with(
				RebatesUpdateActions.RebatesUpdated,
				RebatesUpdateActions.RebatesBulkUpdated,
				() => ({
					translationPath: 'rebatesUpdate.success.rebatesUpdated'
				})
			)
			.with(RebatesUpdateActions.RebateDeleted, () => ({
				translationPath: 'rebatesUpdate.success.rebateDeleted'
			}))
			.with(RejectsLoadActions.RejectsLoadFileAdded, () => ({
				translationPath:
					'rejectsLoad.success.loadRejectsLoadFileUploaded'
			}))
			.with(RejectsLoadActions.RejectsLoadTemplateDeleted, () => ({
				translationPath:
					'rejectsLoad.success.rejectsLoadTemplateDeleted'
			}))
			.with(RejectsLoadActions.RejectsLoadTemplateSaved, () => ({
				translationPath: 'rejectsLoad.success.rejectsLoadTemplateSaved'
			}))
			.with(RejectsLoadActions.ProcessRejectsLoadFile, () => ({
				translationPath:
					'rejectsLoad.success.rejectsLoadFileProcessingStarted'
			}))
			.with(ReportActions.ReportGenerated, () => ({
				translationPath: 'report.success.generationStarted'
			}))
			.with(ReportActions.ReportFileDownloaded, () => ({
				translationPath: 'report.success.downloaded'
			}))
			.with(
				NdcReplacementMappingsActions.NdcReplacementMappingCreated,
				() => ({
					translationPath:
						'ndcReplacementMappings.success.mappingCreated'
				})
			)
			.with(
				NdcReplacementMappingsActions.NdcReplacementMappingUpdated,
				() => ({
					translationPath:
						'ndcReplacementMappings.success.mappingUpdated'
				})
			)
			.with(LineOfBusinessActions.LineOfBusinessCreated, () => ({
				translationPath: 'lineOfBusiness.success.lobCreated'
			}))
			.with(LineOfBusinessActions.LineOfBusinessUpdated, () => ({
				translationPath: 'lineOfBusiness.success.lobUpdated'
			}))
			.with(
				LineOfBusinessSubtypeActions.LineOfBusinessSubtypeCreated,
				() => ({
					translationPath:
						'lineOfBusiness.subtypes.success.lobSubtypeCreated'
				})
			)
			.with(
				LineOfBusinessSubtypeActions.LineOfBusinessSubtypeUpdated,
				() => ({
					translationPath:
						'lineOfBusiness.subtypes.success.lobSubtypeUpdated'
				})
			)
			.with(
				PayorsActions.CustomerPayorsReviewed,
				PayorsActions.CustomerFilteredPayorsReviewed,
				() => ({
					translationPath: 'payors.success.customerPayorsReviewed'
				})
			)
			.with(
				PayorsActions.CustomerPayorsExcluded,
				PayorsActions.CustomerFilteredPayorsExcluded,
				() => ({
					translationPath: 'payors.success.customerPayorsExcluded'
				})
			)
			.with(
				UnbreakableQuantityActions.UnbreakableQuantityCreated,
				() => ({
					translationPath:
						'unbreakableQuantity.success.unbreakableQuantityCreated'
				})
			)
			.with(
				UnbreakableQuantityActions.UnbreakableQuantityUpdated,
				() => ({
					translationPath:
						'unbreakableQuantity.success.unbreakableQuantityUpdated'
				})
			)
			.with(
				UnbreakableQuantityActions.UnbreakableQuantityDeleted,
				() => ({
					translationPath:
						'unbreakableQuantity.success.unbreakableQuantityDeleted'
				})
			)
			.with(RebatesLoadActions.PharmacyIdReviewActionExecuted, () => ({
				translationPath:
					'rebatesLoad.success.pharmacyIdReviewActionExecutionStarted'
			}))
			.with(RebateGroupActions.RebateGroupCreated, () => ({
				translationPath: 'rebateGroup.success.rebateGroupCreated'
			}))
			.with(RebateGroupActions.RebateGroupUpdated, () => ({
				translationPath: 'rebateGroup.success.rebateGroupUpdated'
			}))
			.with(RebateGroupActions.RebateGroupDeleted, () => ({
				translationPath: 'rebateGroup.success.rebateGroupDeleted'
			}))
			.with(RebateGroupActions.RebateGroupFormularyCreated, () => ({
				translationPath:
					'rebateGroup.success.rebateGroupFormularyCreated'
			}))
			.with(RebateGroupActions.RebateGroupFormularyUpdated, () => ({
				translationPath:
					'rebateGroup.success.rebateGroupFormularyUpdated'
			}))
			.with(RebateGroupActions.RebateGroupFormularyDeleted, () => ({
				translationPath:
					'rebateGroup.success.rebateGroupFormularyDeleted'
			}))
			.with(RebateGroupActions.RebateGroupRuleCreated, () => ({
				translationPath: 'rebateGroup.success.rebateGroupRuleCreated'
			}))
			.with(RebateGroupActions.RebateGroupRuleUpdated, () => ({
				translationPath: 'rebateGroup.success.rebateGroupRuleUpdated'
			}))
			.with(RebateGroupActions.RebateGroupRuleDeleted, () => ({
				translationPath: 'rebateGroup.success.rebateGroupRuleDeleted'
			}))
			.with(ExcludedPharmacyActions.ExcludedPharmacyCreated, () => ({
				translationPath:
					'excludedPharmacy.success.excludedPharmacyCreated'
			}))
			.with(ExcludedPharmacyActions.ExcludedPharmacyUpdated, () => ({
				translationPath:
					'excludedPharmacy.success.excludedPharmacyUpdated'
			}))
			.with(ExcludedPharmacyActions.ExcludedPharmacyDeleted, () => ({
				translationPath:
					'excludedPharmacy.success.excludedPharmacyDeleted'
			}))
			.otherwise(() => null);
	}
}
